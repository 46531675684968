import { Injectable } from '@angular/core';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeasibilityValidatorResponseDto } from '../schedule-feasibility-errors/schedule-feasibility-errors.models';
import { ScheduleInfo, ScheduleInfoOptimiserObjective, SchedulePlanBulkUpdateRequest } from '../schedules.models';
import { SeasonCode } from '@shared/models/seasons.model';

@Injectable({
  providedIn: 'root'
})
@ConfigEndpoint({ version: 2 })
export class ScheduleDetailsHttpService {
  private apiUrl: string;
  constructor(private http: HttpClient) {}

  getScheduleInfo(id: number, seasonCode: SeasonCode): Observable<ScheduleInfo> {
    return this.http.get<ScheduleInfo>(`${this.apiUrl}/${seasonCode}/schedule-info/${id}`);
  }

  bulkUpdateRows(scheduleId: number, body: Partial<SchedulePlanBulkUpdateRequest>): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/market/bulk-update`, body);
  }

  validateSchedule(scheduleId: number): Observable<FeasibilityValidatorResponseDto> {
    return this.http.get<FeasibilityValidatorResponseDto>(`${this.apiUrl}/validator/validate/${scheduleId}`);
  }

  // Schedule info optimiser objective

  getScheduleInfoOptimiserObjective(seasonCode: SeasonCode): Observable<ScheduleInfoOptimiserObjective[]> {
    return this.http.get<ScheduleInfoOptimiserObjective[]>(
      `${this.apiUrl}/${seasonCode}/schedule-info/optimiser/objective`
    );
  }
}
