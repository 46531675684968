import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { StatusResponse } from '@shared/models/response.interface';
import {
  ExportSSIMParams,
  OptimiserResultDto,
  OptimiserResultLineDto
} from '@features/schedules/flying-lines/flying-lines.model';
import { environment } from '@environments/environment';
import { AssociatedSlot } from '@features/historic-slots/historic-slots.models';
import { ScheduleInfo } from '@features/schedules/schedules.models';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class FlyingLinesHttpService {
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getOptimiserResult(scheduleId: number, queryParams: string = ''): Observable<OptimiserResultDto> {
    const url = `${environment.javaApiUrl}/v2/noseason/schedule/${scheduleId}/flyingline/result/full${
      queryParams ? '?' + queryParams : ''
    }`;
    return this.http.get<OptimiserResultDto>(url);
  }

  updateScheduleLine(line: OptimiserResultLineDto, scheduleId: number): Observable<OptimiserResultLineDto> {
    return this.http.put<OptimiserResultLineDto>(
      `${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/${line.schedule_id}`,
      line
    );
  }

  addScheduleLine(line: OptimiserResultLineDto, scheduleId: number): Observable<OptimiserResultLineDto> {
    return this.http.post<OptimiserResultLineDto>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline`, line);
  }

  deleteScheduleLine(scheduleId: number, lineId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/${lineId}`);
  }

  duplicateFlyingLine(scheduleId: number, lineId: number): Observable<OptimiserResultLineDto> {
    return this.http.post<OptimiserResultLineDto>(
      `${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/duplicate/${lineId}`,
      null
    );
  }

  getInputJson(scheduleId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/solver/input-json`);
  }

  downloadFile(scheduleId: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/noseason/schedule/${scheduleId}/solver/files`, { responseType: 'blob' });
  }

  getFeasibilityResult(scheduleId: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/noseason/schedule/${scheduleId}/feasibility/input-dto/validate`, {
      responseType: 'blob'
    });
  }

  createTargetSchedule(scheduleId: number): Observable<ScheduleInfo> {
    return this.http.post<ScheduleInfo>(`${this.apiUrl}/noseason/schedule/${scheduleId}/solver/target/schedule`, null);
  }

  unfreezeLine(scheduleId: number, lineId: number): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/${lineId}/unfreeze`, {
      lineId,
      scheduleId
    });
  }

  freezeLine(scheduleId: number, lineId: number): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/${lineId}/freeze`, {
      lineId,
      scheduleId
    });
  }

  bulkDeleteLines(scheduleId: number, lineIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/bulk-delete-line`, lineIds);
  }

  bulkFreezeLines(scheduleId: number, lineIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/bulk-freeze-line`, lineIds);
  }

  bulkUnfreezeLines(scheduleId: number, lineIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/bulk-unfreeze-line`, lineIds);
  }

  unfreezeSector(scheduleId: number, sectorId: number): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/sector/${sectorId}/unfreeze`,
      { sectorId, scheduleId }
    );
  }

  freezeSector(scheduleId: number, sectorId: number): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/noseason/schedule/${scheduleId}/flyingline/sector/${sectorId}/freeze`, {
      sectorId,
      scheduleId
    });
  }

  exportSSIM(seasonCode: SeasonCode, scheduleId: number, exportParams: ExportSSIMParams): Observable<Blob> {
    const params = new HttpParams({ fromObject: exportParams });
    return this.http.get(`${this.apiUrl}/${seasonCode}/ssim/export/${scheduleId}`, {
      params,
      responseType: 'blob'
    });
  }

  assignFlightNumbers(scheduleId: number): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${this.apiUrl}/noseason/schedule/${scheduleId}/solver/assign-numbers`, null);
  }

  assignFlightNumbersFromOtherSchedule(currentScheduleId: number, otherScheduleId: number): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.apiUrl}/noseason/schedule/${currentScheduleId}/solver/assign/from-other-schedule/${otherScheduleId}`,
      null
    );
  }

  addAssociatedSectorSlot(
    scheduleId: number,
    seasonCode: SeasonCode,
    sectorId: number,
    type: 'DEPARTURE' | 'ARRIVAL'
  ): Observable<AssociatedSlot> {
    return this.http.post<AssociatedSlot>(
      `${this.apiUrl}/${seasonCode}/schedule/${scheduleId}/flyingline/result/${sectorId}/slot?type=${type}`,
      null
    );
  }
}
